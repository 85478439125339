import React, { VFC, memo } from 'react'
import Font from '@sport1/news-styleguide/Font'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import TeamStructureList from '@sport1/news-styleguide/TeamStructureList'
import { SubstitutionClientModel } from '@sport1/types/sportData'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import { createPlayerInfoHref } from '@/utils/navigation/Navigator'
import getPlayerName from '@/utils/sportsData/getPlayerName'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'

interface SubstitutionProps extends SystemSpacingsProps {
    substitutions: SubstitutionClientModel[]
    testID?: string
}

const Substitution: VFC<SubstitutionProps> = ({ substitutions, marginTop, testID }) => (
    <>
        <NonFlexingContainer
            marginTop={marginTop}
            paddingY="spacing-6"
            testID={`${testID}-container`}
        >
            <Font
                fontVariant="Display-S"
                fontFamilyVariant="Sport1-CondensedBlackItalic"
                nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
            >
                Auswechslung
            </Font>
        </NonFlexingContainer>
        {substitutions.map((substitution, substitutionIndex) => {
            if (substitution.playerIn && substitution.playerOut) {
                return (
                    <TeamStructureList
                        key={`${substitution.minute}_${substitutionIndex}`}
                        type="SUBSTITUTION"
                        substitutionMinute={`${substitution.minute}'`}
                        name={getPlayerName(substitution.playerIn, 'firstname lastname')}
                        imageUrl={substitution.playerIn.imageUrl}
                        alternatePlayerName={getPlayerName(
                            substitution.playerOut,
                            'firstname lastname'
                        )}
                        alternatePlayerImageUrl={substitution.playerOut.imageUrl}
                        showBorderBottom={substitutionIndex + 1 === substitutions.length}
                        playerInHref={createPlayerInfoHref(substitution.playerIn)}
                        playerOutHref={createPlayerInfoHref(substitution.playerOut)}
                        testID={testID}
                    />
                )
            }
        })}
    </>
)

export default memo(Substitution)
