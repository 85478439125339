import React, { VFC, memo } from 'react'
import Font from '@sport1/news-styleguide/Font'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import TeamStructureList from '@sport1/news-styleguide/TeamStructureList'
import { PlayerWithEventsClientModel } from '@sport1/types/sportData'
import Types, { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import { createPlayerInfoHref } from '@/utils/navigation/Navigator'
import getPlayerName from '@/utils/sportsData/getPlayerName'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'

interface PlayerListProps extends SystemSpacingsProps {
    headline: string
    player: PlayerWithEventsClientModel[]
    testID?: string
}

const mapEventsToAdditionaInfoIcons = (events: string[] = []): Types.AdditionaInfoIcons[] =>
    events.map(event => {
        if (event === 'SUBSTITUTE_IN' || event === 'SUBSTITUTE_OUT') {
            return 'SUBSTITUTION'
        }
        return event
    }) as Types.AdditionaInfoIcons[]

const PlayerList: VFC<PlayerListProps> = ({
    player,
    headline,
    paddingTop = 'spacing-9',
    testID,
}) => (
    <>
        <NonFlexingContainer
            paddingTop={paddingTop}
            paddingBottom="spacing-6"
            testID={`${testID}-container`}
        >
            <Font
                fontVariant="Display-S"
                fontFamilyVariant="Sport1-CondensedBlackItalic"
                nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
            >
                {headline}
            </Font>
        </NonFlexingContainer>
        {player.map((playerItem, playerIndex) => {
            const playerName = getPlayerName(playerItem, 'firstname lastname')
            return (
                <TeamStructureList
                    key={`${playerName}-${playerItem.id}`}
                    type="ON_BENCH_PLAYER"
                    name={playerName}
                    imageUrl={playerItem.imageUrl}
                    jerseyNumber={playerItem.jerseyNumber || 0}
                    additionalInfoIconsList={mapEventsToAdditionaInfoIcons(playerItem.events)}
                    showBorderBottom={playerIndex + 1 === player.length}
                    href={createPlayerInfoHref(playerItem)}
                    testID={`${testID}-${playerIndex}`}
                />
            )
        })}
    </>
)

export default memo(PlayerList)
