import React, { FunctionComponent, memo } from 'react'
import { MatchStatisticsGroupModel } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import MatchStatistic from '../MatchStatistic'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'

type Props = Omit<MatchStatisticsGroupModel, 'type'> & SystemSpacingsProps

const MatchStatisticGroup: FunctionComponent<Props> = ({ title, statistics, marginTop }) => {
    return (
        <NonFlexingContainer testID="match-statistic-group-container" marginTop={marginTop}>
            <Font
                fontVariant="Display-S"
                fontFamilyVariant="Sport1-CondensedBlackItalic"
                nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
            >
                {title}
            </Font>
            {statistics.map(({ title, type, home, away }, index) => (
                <MatchStatistic
                    key={`${type}_${home}_${away}`}
                    title={title}
                    type={type}
                    home={home}
                    away={away}
                    marginTop={index === 0 ? 'spacing-7' : 'spacing-9'}
                />
            ))}
        </NonFlexingContainer>
    )
}

export default memo(MatchStatisticGroup)
