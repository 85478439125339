import React, { VFC, memo } from 'react'
import Font from '@sport1/news-styleguide/Font'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import TeamStructureList from '@sport1/news-styleguide/TeamStructureList'
import { TeamOfficialClientModel } from '@sport1/types/sportData'
import getPlayerName from '@/utils/sportsData/getPlayerName'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'

type TrainerProps = {
    trainer: TeamOfficialClientModel
    testID?: string
}

const Trainer: VFC<TrainerProps> = ({ trainer, testID }) => (
    <>
        <NonFlexingContainer
            paddingTop="spacing-9"
            paddingBottom="spacing-6"
            testID={`${testID}-container`}
        >
            <Font
                fontVariant="Display-S"
                fontFamilyVariant="Sport1-CondensedBlackItalic"
                nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
            >
                Trainer
            </Font>
        </NonFlexingContainer>
        <TeamStructureList
            type="TEAM_COACH"
            name={getPlayerName(trainer, 'firstname lastname')}
            imageUrl={trainer.imageUrl}
        />
    </>
)

export default memo(Trainer)
